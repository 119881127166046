import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export interface Props {
  circleColor?: string;
  arrowColor?: string;
  className?: string;
}

const ForwardIcon = ({circleColor = '#FF871C', arrowColor = 'black', className}: Props): JSX.Element => {
  return (
    <SvgIcon width="56.72" height="56.77" viewBox="0 0 56.72 56.77" fill="none"
             className={ className }>
      <path fill={ arrowColor }
            d="M34,19.54l-1.09,1.1a.21.21,0,0,0,0,.31L39,27.06a.22.22,0,0,1-.16.38H13.78a.23.23,0,0,0-.23.23v1.55a.23.23,0,0,0,.23.22h25.1a.23.23,0,0,1,.16.39l-6,6a.22.22,0,0,0,0,.32l1.09,1.09a.22.22,0,0,0,.32,0l8.63-8.63a.22.22,0,0,0,0-.32l-8.76-8.76A.22.22,0,0,0,34,19.54Z"/>
      <path fill={ circleColor }
            d="M28.35,56.75A28.36,28.36,0,1,1,56.7,28.4,28.39,28.39,0,0,1,28.35,56.75ZM28.35,2A26.36,26.36,0,1,0,54.7,28.4,26.4,26.4,0,0,0,28.35,2Z"/>
    </SvgIcon>
  )
}

export default ForwardIcon
